import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../config/supabase";
import { AuthContext } from "../../context/AuthContext";
import ActivityCard from "../Shared/ActivityCard";
import ChatNav from "../Shared/ChatNav";
import Modal from "../Shared/Modal";
import "./DetailChatActivity.css";

const DetailChatActivity = () => {
  const [endedChats, setEndedChats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.id;

  const handleModal = (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowModal(!showModal);
  };

  const handleDeleteSession = async () => {
    if (!selectedSessionId) return;

    const { error } = await supabase
      .from("Session")
      .delete()
      .match({ SessionId: selectedSessionId, UserId: userId });

    if (!error) {
      setEndedChats(
        endedChats.filter((chat) => chat.SessionId !== selectedSessionId)
      );
      setShowModal(false);
      setSelectedSessionId(null);
    } else {
      console.error("Error deleting session:", error);
    }
  };

  useEffect(() => {
    const fetchChats = async () => {
      if (userId == null) return;

      const { data, error } = await supabase
        .from("Session")
        .select()
        .eq("UserId", userId)
        .is("isActive", false)
        .not("EndedAt", "is", null);

      if (!error) {
        setEndedChats(data);
      } else {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
    console.log(endedChats);
  }, [userId]);

  return (
    <div className="ended_content">
      <div className="ended_nav">
        <ChatNav title="历史记录" />
      </div>
      {showModal && (
        <Modal
          prompt="delete"
          onButtonClick={() => handleModal(selectedSessionId)}
          onDelete={handleDeleteSession}
        />
      )}
      <div className="ended_page">
        <div className="detail_activity_chats_content">
          {endedChats.map((chat) => (
            <ActivityCard
              key={chat.SessionId}
              startAt={chat.StartedAt}
              endedAt={chat.EndedAt}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailChatActivity;
