// ProtectedRoute.js
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
const ProtectedRoute = ({ children }) => {
  const { currentUser, firstName } = useContext(AuthContext);
  if (currentUser == null) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default ProtectedRoute;
