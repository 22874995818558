import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Navbar from "../Shared/NavBar";
import "./Home.css";
import { supabase } from "../../config/supabase";

const Home = () => {
  const { firstName } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const createSession = async (UserId) => {
    const { data: sessionData, error: newSessionError } = await supabase
      .from("Session")
      .insert([
        {
          UserId,
          StartedAt: new Date().toISOString(),
          EndedAt: null,
          isActive: true,
          LastMessage: `Hello ${firstName}! I'm ReX 😁`,
        },
      ])
      .single()
      .select();

    if (newSessionError) {
      console.error(newSessionError);
      return;
    }
    console.log(sessionData.SessionId);
    const sessionId = sessionData.SessionId;

    navigate(`/chat/${sessionId}`);
  };

  return (
    <div className="container">
      <Navbar />
      <div className="page_wrap">
        <img
          className="welcome_img"
          src="assets/img/ReX_welcome_graphic.png"
          alt="ReX"
        />

        <span className="welcome__text">
          {firstName && `欢迎, ${firstName}! 👋`}
        </span>
        <span className="career_help">请与REX助手畅所欲言吧!</span>
        <span className="career_help_comment">
          开始一个新的对话，让我们一起探讨吧！
        </span>
        <button
          className="start_button"
          onClick={() => {
            if (currentUser && currentUser.id) {
              createSession(currentUser.id);
            } else {
              console.error("Current user or user ID is undefined.");
            }
          }}
        >
          开始聊天
        </button>
      </div>
    </div>
  );
};

export default Home;
