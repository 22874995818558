import React from "react";
import "./ActivityCard.css";
const formatSessionTimes = (startAt, endAt) => {
  const start = new Date(startAt);
  const end = new Date(endAt);

  const durationMs = end - start;

  const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
  const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));


  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  };


  const formattedStart = start.toLocaleTimeString("en-US", options);
  const formattedEnd = end.toLocaleTimeString("en-US", options);


  return `${formattedStart} - ${formattedEnd} (${durationHours} hours, ${durationMinutes} minutes)`;
};

const ActivityCard = ({ endedAt, startAt }) => {
  console.log(`endAt: ${endedAt}`);
  console.log(`startAt: ${startAt}`);
  const duration = formatSessionTimes(startAt, endedAt);
  console.log(`duration: ${duration}`);
  if (endedAt) {
    const date = new Date(endedAt); 

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    endedAt = formattedDate;
  }

  return (
    <div className="activity-card">
      <img
        src="assets/img/Time Circle.png"
        alt="TimeCircle"
        className="timeCircle"
      />
      <div className="chat-card__content">
        <div className="conversation_title">ReX {`- ${endedAt}`}</div>
        <div className="session-duration">{duration}</div>
      </div>
    </div>
  );
};
export default ActivityCard;
