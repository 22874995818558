import { Routes, Route } from "react-router-dom";

import Home from "./components/Home/Home";
import Chat from "./components/Chat/Chat";
import "./App.css";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ProtectedRoute from "./ProtectedRoute";
import ChatHistory from "./components/ChatHistory/ChatHistory";
import Statistic from "./components/Statistic/Statistic";
import { ChatProvider } from "./context/ChatContext";
import EndedChats from "./components/EndedChats/EndedChats";
import DetailChatActivity from "./components/DetailChatActivity/DetailChatActivity";
const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat/:sessionId"
          element={
            <ProtectedRoute>
              <ChatProvider>
                <Chat />
              </ChatProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat-history"
          element={
            <ProtectedRoute>
              <ChatHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ended-chats"
          element={
            <ProtectedRoute>
              <EndedChats />
            </ProtectedRoute>
          }
        />
        <Route
          path="/statistics"
          element={
            <ProtectedRoute>
              <Statistic />
            </ProtectedRoute>
          }
        />
        <Route
          path="/detail-chat-activity"
          element={
            <ProtectedRoute>
              <DetailChatActivity />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
