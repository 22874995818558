import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./Message.css";

const Message = ({ text, isOwner }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} className={isOwner ? "message-owner" : "message-bot"}>
      <div className="messageContent">
        <ReactMarkdown className="messageText">{text}</ReactMarkdown>
        {/* <p className="messageText">{text}</p> */}
      </div>
    </div>
  );
};
export default Message;
