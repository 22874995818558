import { createContext, useState, useEffect, useContext } from "react";
import { supabase } from "../config/supabase"; 

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    setCurrentUser(supabase.auth.getSession()?.user || null);
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setCurrentUser(session?.user || null);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []); 
  useEffect(() => {
    
    if (currentUser) {
      console.log(currentUser.id);
      supabase
        .from('User')
        .select('FirstName')
        .eq('Uid', currentUser.id)  
        .then(({ data, error }) => {
          if (error) {
            console.error('Error fetching user data:', error);
          } else {
            if (data.length > 0) {
              setFirstName(data[0].FirstName);
              console.log('User data found:', data[0].FirstName);
            } else {
              console.log('No user data found.');
            }
          }
        });
    }
  }, [currentUser]);  
  return (
    <AuthContext.Provider value={{ currentUser, firstName }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
