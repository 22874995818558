import React from "react";
import "./Dropdown.css";

const Dropdown = () => {
  return (
    <div className="dropdown_container">
      <div className="dropdown">
        <span className="dropdown-title">This Week</span>
        <img src="/assets/img/Dropdown-Arrow.svg" alt="Arrow-Down" />
      </div>
    </div>
  );
};

export default Dropdown;
