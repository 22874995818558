import React from "react";
import "./NavBar.css";
import { supabase } from "../../config/supabase";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const LogOut = async () => {
    try {
      await supabase.auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div
        className="navbar-logo"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src="/assets/img/icon.png" alt="ReX" />
        <span className="rex-logo-text">ReX</span>
      </div>
      <div className="navbar-icons">
        <button>
          <img
            src="/assets/img/Setting.svg"
            alt="Settings"
            onClick={() => navigate("/statistics")}
          />
        </button>
        <button>
          <img src="/assets/img/Group.svg" alt="Globe" onClick={LogOut} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
