import React from "react";
import "./ChatCard.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ChatCard = ({
  isActive = false,
  timeStamp,
  message,
  sessionId,
  onButtonClick,
}) => {
  const navigate = useNavigate();
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isSwiped, setIsSwiped] = useState(false);
  const maxSwipeDistance = 60;
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiped(true);
  };

  const handleTouchMove = (e) => {
    const touchCurrentX = e.touches[0].clientX;
    const deltaX = startX - touchCurrentX;

    setCurrentX(Math.min(deltaX, maxSwipeDistance));
  };

  const handleTouchEnd = () => {
    const swipeThreshold = 60;

    if (currentX >= swipeThreshold) {
      console.log("swiped");
    } else {
      setIsSwiped(false);
      setCurrentX(0);
    }
  };
  console.log(timeStamp);
  if (timeStamp) {
    const date = new Date(timeStamp);
    const options = { year: "numeric", month: "short", day: "numeric" };

    const formattedDate = date.toLocaleDateString("en-US", options);
    timeStamp = formattedDate;
}

  const handleClick = () => {
    if (isSwiped) return;
    navigate(`/chat/${sessionId}`);
  };

  const truncate = (str, num) => {
    if (str && str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  const cardStyle = {
    marginRight: isSwiped ? `${Math.min(currentX, maxSwipeDistance)}px` : "0",
    transition: isSwiped
      ? "none"
      : "margin-right 0.3s ease, transform 0.3s ease",
  };

  const truncatedMessage = truncate(message, 50);

  return (
    <div
      className={isSwiped ? "chat-card-container-swipe" : "chat-card-container"}
    >
      <div
        className="chat-card"
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={cardStyle}
      >
        <img src="assets/img/chat_card_avatar.png" alt="Rex" />
        <div className="chat-card__content">
          <div className="conversation_title">
            ReX {!isActive && `- ${timeStamp}`}
          </div>
          <div className="conversation_content">{truncatedMessage}</div>
        </div>
        {isActive && (
          <div className="icon-container">
            <img src="assets/img/Chat.png" alt="icon" className="icon" />
          </div>
        )}
      </div>
      {isSwiped && (
        <img
          className="delete-area"
          src="assets/img/trashbin.png"
          alt="trash"
          onClick={onButtonClick}
        />
      )}
    </div>
  );
};
export default ChatCard;
