import React from "react";
import "./ActivityNavBar.css";
import { useNavigate } from "react-router-dom";
const ActivityNavBar = () => {
  const navigate = useNavigate();
  return (
    <div className="stat-container">
      <div className="left-section">
        <button className="icon-button">
          <img
            src="/assets/img/Arrow - Left.png"
            alt="Exit"
            onClick={() => navigate("/chat-history")}
          />
        </button>
        <span className="activity_title">活动</span>
      </div>
      <div className="right-section">
        <button className="icon-button" aria-label="Options">
          <img src="/assets/img/Option.png" alt="Options" />
        </button>
      </div>
    </div>
  );
};

export default ActivityNavBar;
