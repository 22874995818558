import React, { useContext } from "react";
import Message from "../Message/Message";
import "./Messages.css";
import { ChatContext } from "../../context/ChatContext";
import { AuthContext } from "../../context/AuthContext";
const Messages = () => {
  const { messages } = useContext(ChatContext);
  const { firstName } = useContext(AuthContext);
  return (
    <div className="messages">
      <Message text={`你好 ${firstName}! 我是ReX助手 😁`} />
      {messages.map((msg) => (
        <Message key={msg.id} text={msg.text} isOwner={msg.isOwner} />
      ))}
    </div>
  );
};
export default Messages;
