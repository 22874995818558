import { supabase } from "../../config/supabase";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import ChatCard from "../ChatCard/ChatCard";
import Modal from "../Shared/Modal";
import NavBar from "../Shared/NavBar";
import "./ChatHistory.css";

const ChatHistory = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [activeChats, setActiveChats] = useState([]);
  const [endedChats, setEndedChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { firstName } = useContext(AuthContext);
  const navigate = useNavigate();
  const userId = currentUser.id;
  const [showModal, setShowModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const handleModal = (sessionId) => {
    console.log("Session ID:", sessionId);
    setSelectedSessionId(sessionId);
    console.log(selectedSessionId);
    setShowModal(!showModal);
  };
  console.log(userId);
  const createAnotherSession = async () => {
    const { data, error } = await supabase
      .from("Session")
      .insert([
        {
          UserId: userId,
          StartedAt: new Date(),
          isActive: true,
          LastMessage: `Hello! I'm ReX 😁`,
        },
      ])
      .select();

    if (error) console.log("Error:", error);
    else navigate(`/chat/${data[0].SessionId}`);
  };

  const handleEndedChats = () => {
    navigate("/ended-chats");
  };

  const handleDeleteSession = async () => {
    console.log("Deleting session:", selectedSessionId);
    if (!selectedSessionId) return;

    const { data, error } = await supabase
      .from("Session")
      .delete()
      .match({ SessionId: selectedSessionId });

    if (error) {
      console.error("Delete error:", error);
      return;
    }

    setActiveChats(
      activeChats.filter((chat) => chat.SessionId !== selectedSessionId)
    );
    setEndedChats(
      endedChats.filter((chat) => chat.SessionId !== selectedSessionId)
    );
    setShowModal(false);
    setSelectedSessionId(null);
  };

  useEffect(() => {
    const fetchChats = async () => {
      const { data, error } = await supabase
        .from("Session")
        .select("*")
        .eq("UserId", userId);

      if (error) {
        console.error("Error fetching chats:", error);
        return;
      }

      let active = [];
      let ended = [];

      data.forEach((chat) => {
        if (chat.EndedAt === null && chat.isActive) {
          active.push(chat);
        } else {
          ended.push(chat);
        }
      });

      ended.sort((a, b) =>
        b.EndedAt && a.EndedAt ? b.EndedAt - a.EndedAt : 0
      );
      ended = ended.slice(0, 3);
      setIsEmpty(ended.length === 0);
      setActiveChats(active);
      setEndedChats(ended);
    };

    if (userId) fetchChats();
  }, [userId]);
  return (
    <div>
      <NavBar />
      {showModal && (
        <Modal
          prompt="delete"
          onButtonClick={handleModal}
          onDelete={handleDeleteSession}
        />
      )}
      <div className="chat_history_page">
        <div className="active">
          <div className="active_text">未结束的对话</div>
          {activeChats.map((chat) => (
            <ChatCard
              key={chat.SessionId}
              sessionId={chat.SessionId}
              message={chat.LastMessage}
              isActive={true}
              onButtonClick={() => handleModal(chat.SessionId)}
            />
          ))}
        </div>
        <div className="ended">
          <div className="ended_line">
            <span className="ended_text">已结束的对话</span>
            {!isEmpty && (
              <span className="view_all" onClick={handleEndedChats}>
                View all
              </span>
            )}
          </div>
          {endedChats.map((chat) => (
            <ChatCard
              key={chat.SessionId}
              sessionId={chat.SessionId}
              message={chat.LastMessage}
              timeStamp={chat.EndedAt}
              onButtonClick={() => handleModal(chat.id)}
            />
          ))}
        </div>
      </div>
      <button
        className="start_another_button"
        onClick={() => {
          if (currentUser && userId) {
            createAnotherSession();
          } else {
            console.error("Current user or user ID is undefined.");
          }
        }}
      >
        开启一个全新的对话
      </button>
    </div>
  );
};
export default ChatHistory;
