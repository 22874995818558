import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { supabase } from "../config/supabase";
import { AuthContext } from "./AuthContext";
import { useParams } from "react-router-dom";
import axios from "axios";

export const ChatContext = createContext();
export const ChatProvider = ({ children }) => {
  // const [sessionId, setSessionId] = useState(null);
  const { sessionId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.id;
  const [messages, setMessages] = useState([]);
  console.log(sessionId);
  console.log(userId);
  useEffect(() => {
    if (!userId || !sessionId) {
      return;
    }

    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from("Messages")
        .select("*")
        .eq("SessionId", sessionId)
        .order("CreatedAt", { ascending: true });

      if (error) {
        console.error("Error fetching messages:", error);
      } else {
        const msgs = data.map((msg) => ({
          id: msg.MessagesId,
          ...msg,
          isOwner: msg.type,
        }));
        console.log(msgs);
        setMessages(msgs);
      }
    };
    fetchMessages();

    const subscription = supabase
      .channel("public:Messages")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "Messages",
          filter: `SessionId=eq.${sessionId}`,
        },
        (payload) => {
          console.log("New message received:", payload);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              id: payload.new.MessagesId,
              ...payload.new,
              isOwner: payload.new.type,
            },
          ]);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [userId, sessionId]);

  const sendMessage = async (message) => {
    if (!message.trim() || !userId || !sessionId) return;

    try {
      const { data: newMessage, error: addMessageError } = await supabase
        .from("Messages")
        .insert([
          {
            text: message,
            CreatedAt: new Date().toISOString(),
            type: true,
            SessionId: sessionId,
          },
        ]);

      if (addMessageError) {
        throw new Error(addMessageError.message);
      }

      const { error: updateSessionError } = await supabase
        .from("Session")
        .update({
          LastMessage: message,
          isActive: true,
        })
        .eq("SessionId", sessionId);

      if (updateSessionError) {
        throw new Error(updateSessionError.message);
      }

      const response = await fetch(
        "https://kvonkqcuskfcgndmewij.supabase.co/functions/v1/chatgpt",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: message }),
        }
      );
      console.log(response);
      const responseMessage = await response.text();
      console.log(responseMessage);

      const { data: responseMsg, error: addResponseError } = await supabase
        .from("Messages")
        .insert([
          {
            text: responseMessage,
            CreatedAt: new Date().toISOString(),
            type: false,
            SessionId: sessionId,
          },
        ]);

      if (addResponseError) {
        throw new Error(addResponseError.message);
      }

      const { error: updateResponseSessionError } = await supabase
        .from("Session")
        .update({
          LastMessage: responseMessage,
          isActive: true,
        })
        .eq("SessionId", sessionId);

      if (updateResponseSessionError) {
        throw new Error(updateResponseSessionError.message);
      }

      console.log("Message and response handled successfully");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <ChatContext.Provider value={{ messages, sendMessage }}>
      {children}
    </ChatContext.Provider>
  );
};
