import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ActivityNavBar from "../Shared/ActivityNavBar";
import ActivityCard from "../Shared/ActivityCard";
import Dropdown from "../Shared/Dropdown";
import CustomLineChart from "../Shared/CustomLineChart";
import { supabase } from "../../config/supabase";
import "./Statistic.css";

const Statistic = () => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [endedChats, setEndedChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser?.id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChats = async () => {
      if (!userId) return;

      const { data, error } = await supabase
        .from("Session")
        .select()
        .eq("UserId", userId)
        .is("isActive", false)
        .not("EndedAt", "is", null)
        .order("EndedAt", { ascending: false });
      if (error) {
        console.error("Error fetching chats:", error);
      } else {
        const ended = data.slice(0, 3);
        setEndedChats(ended);
        setIsEmpty(ended.length === 0);
      }
    };
    fetchChats();
  }, [userId]);

  return (
    <div>
      <ActivityNavBar />
      <div className="activity-wrapper">
        <div className="stat-content">
          <div className="chart-part">
            <div className="timeOption">
              <span className="timeOption-title">数据统计</span>
              <Dropdown />
            </div>
            <div className="statDescribe">这周您与ReX的对话时间</div>
            <div>
              <CustomLineChart />
            </div>
          </div>
        </div>
        <div className="detail-chat-activity-wrapper">
          <div className="detail-text">
            <span className="detail-chat-activity-title">对话历史</span>
            {!isEmpty && (
              <span
                className="see_all"
                onClick={() => navigate("/detail-chat-activity")}
              >
                See All
              </span>
            )}
          </div>
          <div className="history-session-time">
            {endedChats.map((chat) => (
              <ActivityCard
                key={chat.SessionId}
                startAt={chat.StartedAt}
                endedAt={chat.EndedAt}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistic;
