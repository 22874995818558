import React, { useContext, useEffect, useState } from "react";
import "./EndedChats.css";
import ChatCard from "../ChatCard/ChatCard";
import { supabase } from "../../config/supabase"; // 导入Supabase客户端
import { AuthContext } from "../../context/AuthContext";
import ChatNav from "../Shared/ChatNav";
import Modal from "../Shared/Modal";

const EndedChats = () => {
  const [endedChats, setEndedChats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.id;

  const handleModal = (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowModal(!showModal);
  };

  const handleDeleteSession = async () => {
    if (!selectedSessionId) return;

    const { error } = await supabase
      .from("Session")
      .delete()
      .match({ SessionId: selectedSessionId, UserId: userId });

    if (error) {
      console.error("Delete error:", error);
    } else {
      setEndedChats(
        endedChats.filter((chat) => chat.SessionId !== selectedSessionId)
      );
      setShowModal(false);
      setSelectedSessionId(null);
    }
  };

  useEffect(() => {
    const fetchChats = async () => {
      if (!userId) return;

      const { data, error } = await supabase
        .from("Session")
        .select()
        .eq("UserId", userId)
        .is("isActive", false)
        .not("EndedAt", "is", null);

      if (error) {
        console.log("Fetch error:", error);
      } else {
        setEndedChats(data.map((chat) => ({ id: chat.SessionId, ...chat })));
      }
    };

    fetchChats();
  }, [userId]);

  return (
    <div className="ended_content">
      <div className="ended_nav">
        <ChatNav title="已结束的对话" />
      </div>
      {showModal && (
        <Modal
          prompt="delete"
          onButtonClick={handleModal}
          onDelete={handleDeleteSession}
        />
      )}
      <div className="ended_page">
        <div className="ended_chats_content">
          {endedChats.map((chat) => (
            <ChatCard
              key={chat.SessionId}
              message={chat.LastMessage}
              timeStamp={chat.EndedAt}
              onButtonClick={() => handleModal(chat.SessionId)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EndedChats;
