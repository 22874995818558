import React from "react";
import "./ToggleMenu.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { supabase } from "../../config/supabase";
import { useContext } from "react";


const ToggleMenu = React.forwardRef((props, ref) => {
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser.id;
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const handleEndSession = async () => {
    console.log("End Session");
    const { data: messageData, error: messageError } = await supabase
      .from('Messages')
      .select('text')
      .eq('SessionId', sessionId)
      .order('CreatedAt', { ascending: false })
      .limit(1);
    
    if (messageError) {
      console.error('Error fetching last message:', messageError);
      return;
    }
  
    const lastMessageData = messageData.length > 0 ? messageData[0].text : null;
    console.log(lastMessageData);

    const { error: updateError } = await supabase
      .from('Session')
      .update({
        LastMessage: lastMessageData,
        isActive: false,
        EndedAt: new Date()
      })
      .eq('SessionId', sessionId);
  
    if (updateError) {
      console.error('Error updating session:', updateError);
      return;
    }
  
    navigate("/chat-history");
  };
  
  return (
    <div className="dropdown-content" ref={ref}>
      <div className="dropdown-container">
        <div className="dropdown-item-clear">
          <img src="/assets/img/Close_Square.png" alt="close" />
          <span>Clear Chat</span>
        </div>
        <div className="divider-2"></div>
        <div className="dropdown-item-export">
          <img src="/assets/img/Arrow - Down Square.png" alt="export" />
          <span>Export Chat</span>
        </div>
        <div className="divider-2"></div>
        <div className="dropdown-item-end" onClick={handleEndSession}>
          <img src="/assets/img/Logout.png" alt="logout" />
          <span>End Session</span>
        </div>
      </div>
    </div>
  );
});

export default ToggleMenu;
