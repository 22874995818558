import React from "react";
import "./Modal.css";
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Modal = ({ prompt, onButtonClick, onDelete }) => {
  const capitalizedPrompt = capitalizeFirstLetter(prompt);
  return (
    <div className="modal">
      <div className="modal-content">
        <img src="/assets/img/Modal.png" alt="Modal"></img>
        <div className="modal-body">
          <div className="modal-body-title">{capitalizedPrompt} Chat?</div>
          <div className="modal-body-text">
            Are you sure you want to {prompt} this ended chat?`
          </div>
        </div>
        <div className="modal-footer">
          <button className="confirm-btn" onClick={onDelete}>
            Yes, {prompt}
          </button>
          <button className="cancel-btn" onClick={onButtonClick}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default Modal;
