import React, { useContext, useState } from "react";
import { ChatContext } from "../../context/ChatContext";
import "./SendBox.css";

const Sendbox = () => {
  const [message, setMessage] = useState("");
  const { sendMessage } = useContext(ChatContext);
  const handleSendMessage = () => {
    console.log(message);
    sendMessage(message);

    setMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="sendboxContainer">
      <textarea
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type a message to Rex..."
        className="messageInput"
      />
      <button onClick={handleSendMessage} className="sendButton">
        <img src="/assets/img/Send.png" className="sendIcon" alt="sendIcon" />
      </button>
    </div>
  );
};

export default Sendbox;
