import React from "react";
import "./ChatNav.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ToggleMenu from "./ToggleMenu";
import { useRef } from "react";
import { useEffect } from "react";

const ChatNav = ({ title }) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef();
  const buttonRef = useRef();

  const toggleMenu = (e) => {
    setShowMenu(!showMenu);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const closeMenu = (e) => {
      if (
        showMenu &&
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", closeMenu);

    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, [showMenu]);
  return (
    <div className="chatnav-container">
      <div className="left-section">
        <button className="icon-button">
          <img
            src="/assets/img/Arrow - Left.png"
            alt="Exit"
            onClick={() => navigate("/chat-history")}
          />
        </button>
        <span className="chat-name">{title}</span>
      </div>
      <div className="right-section">
        <button className="icon-button" aria-label="Search">
          <img src="/assets/img/Search.png" alt="Search" />
        </button>
        <button
          className="icon-button"
          aria-label="Options"
          onClick={toggleMenu}
          ref={buttonRef}
          disabled={title === "已结束的对话"}
        >
          <img src="/assets/img/Option.png" alt="Options" />
        </button>
        {showMenu && <ToggleMenu ref={menuRef} className="menu" />}
      </div>
    </div>
  );
};

export default ChatNav;
