import React from "react";
import Messages from "../Messages/Messages";
import ChatNav from "../Shared/ChatNav";
import SendBox from "../Shared/SendBox";
import "./Chat.css";
const Chat = () => {
  return (
    <div className="chat_page">
      <ChatNav title="ReX" />
      <div>
        <img
          className="chat_icon"
          src="/assets/img/Rex_img.png"
          alt="ReX_icon"
        />
      </div>
      <Messages />
      <div className="sendwrap">
        <SendBox />
      </div>
    </div>
  );
};

export default Chat;
