import React, { useRef, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: false,
      min: 1,
      max: 6,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        stepSize: 1,
        display: true,
        callback: function (value, index, values) {
          return value + "h";
        },
      },
    },
    x: {
      offset: true,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        display: true,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
    },
    filler: {
      propagate: true,
    },
  },
};

const CustomLineChart = () => {
  const chartRef = useRef(null);
  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, "rgba(0, 205, 189, 0.24)");
      gradient.addColorStop(1, "rgba(0, 205, 189, 0)");

      const chartData = chart.config.data;
      chartData.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "Hours",
        data: [5, 3, 4, 5, 4, 5, 6],
        fill: true,
        borderColor: "#876DFF",
        borderWidth: 5,
        pointRadius: 0,
        pointBackgroundColor: "#876DFF",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#876DFF",
      },
    ],
  };

  return (
    <div style={{ position: "relative", height: "300px" }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default CustomLineChart;
